<script>
import { mapGetters } from 'vuex'

const soybean_white_mold = 13

export default {
  computed: {
    ...mapGetters('seasonsCrops', ['seasonCropsDiseases']),
    ...mapGetters('program', ['hasProgramWithWhiteMold']),

    hasCropDiseases() {
      return this.cropDiseasesFiltered?.length > 0
    },

    ignoredDiseases() {
      const ignoredDiseases = []

      if (!this.hasProgramWithWhiteMold) {
        ignoredDiseases.push(soybean_white_mold)
      }
      return ignoredDiseases
    },

    cropDiseasesFiltered() {
      return (
        this.seasonCropsDiseases?.filter((disease) => {
          if (
            !this.hasProgramWithWhiteMold &&
            disease.id == soybean_white_mold
          ) {
            return false
          }
          return true
        }) ?? []
      )
    },
  },
}
</script>
