import { HorizontalBar } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import CropDiseasesMixin from '@/components/Spraying/CropDiseasesMixin'
import { formatDecimal } from '@/language'

export default {
  mixins: [CropDiseasesMixin],
  extends: HorizontalBar,

  props: {
    chartdata: {
      type: Array,
      default: null,
    },
    hideLabels: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Object,
      default: () => null,
    },
  },

  watch: {
    chartdata: {
      handler() {
        this.renderChart(this.mountChartStructure, this.computedOptions)
      },
      deep: true,
    },
  },

  mounted() {
    this.addPlugin([ChartDataLabels])
    this.renderChart(this.mountChartStructure, this.computedOptions)
  },

  computed: {
    diseases() {
      return this.cropDiseasesFiltered?.map((disease) =>
        this.$t(`diseases.${disease.code}`)
      )
    },
    whiteMoldIndex() {
      return this.cropDiseasesFiltered?.findIndex((disease) => disease.id == 13)
    },
    diseasesHideLabels() {
      return this.cropDiseasesFiltered?.map(() => '')
    },
    efficacy() {
      return this.chartdata[0].map(this.applyMaxAndMinValue)
    },
    hasEfficacy() {
      return this.efficacy.length
    },
    maxEfficacy() {
      return this.chartdata[1].map(this.applyMaxAndMinValue)
    },
    withoutRegister() {
      return this.chartdata[2].map((withoutRegister) =>
        withoutRegister == 1 ? 2 : 0
      )
    },

    computedOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        scales: {
          yAxes: [
            {
              gridLines: { display: false },
              stacked: true,
            },
          ],
          xAxes: [
            {
              gridLines: { display: false },
              stacked: true,
              ticks: {
                max: 1.2, // o .2 é um espaço extra para mostrar o % max na tela
                min: 0,
                display: false,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            labels: {
              title: {
                font: { weight: 'bold' },
              },
            },
          },
        },
        tooltips: {
          callbacks: {
            title(tooltipItem, data) {
              // tooltipItem contém um item por dataset
              // qual item é indiferente, pois _diseases existe em qualquer dataset
              const { index } = tooltipItem[0] // o index é igual em ambos os items
              return data.datasets[0]._diseases[index]
            },

            label(tooltipItem, data) {
              let value = null
              const dataset = data.datasets[tooltipItem.datasetIndex]

              // XXX: o segundo dataset preserva os valores originais em _data, mas desenha
              // a barra do gráfico utilizando somente a diferença entre os datasets
              if (tooltipItem.datasetIndex === 1)
                value = dataset._data[tooltipItem.index]
              else value = dataset.data[tooltipItem.index]

              return `${formatDecimal(value * 100, 2)}%`
            },
          },
        },
      }
    },

    mountChartStructure() {
      const datasets = []
      datasets.push(this.getRealEfficacyBars())
      datasets.push(this.getPotencialEfficacyBars())

      return {
        labels: this.getDiseasesLabels(),
        datasets,
      }
    },
  },

  methods: {
    getDiseasesLabels() {
      const shouldHideLabels = this.hideLabels && !this.mobile

      if (shouldHideLabels) {
        return this.diseasesHideLabels
      } else {
        return this.diseases
      }
    },

    getRealEfficacyBars() {
      const withoutEfficacyLabel = this.$t('spraying.without_efficacy')
      return {
        backgroundColor: this.colorByLevel,
        borderWidth: this.withoutRegister,
        borderColor: '#ff6666',
        borderSkipped: 'right',
        _diseases: this.diseases,
        data: this.efficacy,
        datalabels: {
          anchor: 'start', // anchor to start, end or center of the bar
          align: 'end', // Align to the start of the bar
          padding: { left: -7 },
          formatter(value) {
            if (value <= 0) {
              return withoutEfficacyLabel
            }
            const formattedValue = (value * 100).toFixedNotRounded(0)
            if (formattedValue > 100) {
              return `${100}%`
            }

            if (formattedValue <= 20) {
              return formattedValue
            }

            return `${formattedValue}%`
          },
        },
      }
    },

    getPotencialEfficacyBars() {
      return {
        borderWidth: this.withoutRegister,
        borderColor: '#ff6666',
        _diseases: this.diseases,
        _data: this.maxEfficacy,
        data: this.maxEfficacy.map((maxEfficacy, i) => {
          // assegura que eficacia ajustada nunca é negativa

          const eficaciaAjustada = this.hasEfficacy
            ? this.normalizeEfficacy(i)
            : 0
          const formattedValue = (
            maxEfficacy - eficaciaAjustada
          ).toFixedNotRounded(4)

          if (formattedValue > 100) {
            return 100
          }
          // assumimos que a efic. potencial é sempre maior que a efic. ajustada
          return formattedValue < 0 ? -1 : formattedValue
        }),
        datalabels: {
          anchor: 'end',
          align: 'end',
          padding: { left: 5 },
          formatter(potencialGreaterThanReal, context) {
            if (potencialGreaterThanReal <= 0) {
              return ''
            }

            return `${(
              context.dataset._data[context.dataIndex] * 100
            ).toFixedNotRounded(0)}%`
          },
        },
      }
    },

    applyMaxAndMinValue(value) {
      if (value < 0) {
        return 0
      }
      if (value > 100) {
        return 100
      }
      return value.toFixedNotRounded(4)
    },
    normalizeEfficacy(i) {
      if (this.efficacy[i] < 0) return 0
      return this.efficacy[i]
    },
    colorByLevel(context) {
      const index = context.dataIndex
      const value = context.dataset.data[index]
      let opacity = 0.65

      //// Cálculo com duas cores dinâmicas
      let r,
        g,
        b = 0
      const val = value * 100
      const center = 70

      if (val <= center) {
        r = 255
        g = Math.floor(255 * (val / center))
        b = 54
      } else {
        r = Math.floor(((100 - val) / center) * 255)
        g = 255
        b = 59
      }

      return `rgba(${r},${g},${b},${opacity})`

      //// Cores estáticas
      // return value < 0.6 ? 'rgba(244, 67, 54, 1)' :     // red
      //         value < 0.7 ? 'rgba(255, 152, 0, 1)' :    // orange
      //         value < 0.8 ? 'rgba(255, 235, 59, 1)' :   // yellow
      //         value < 0.9 ? 'rgba(0, 230, 118, 1)' : 	 // green
      //         'rgba(0, 255, 118, 1)'                    // dark green

      //// Cores usando gradient
      // 		let gradientFill = context.chart.ctx.createLinearGradient(0, 0, 240, 0)
      // 		gradientFill.addColorStop(0, 'rgba(244, 67, 54, 1)')
      // 		gradientFill.addColorStop(0.7, 'rgba(255, 235, 59, 1)')
      // 		gradientFill.addColorStop(1, 'rgba(0, 230, 118, 1)')
      // 		return gradientFill
    },
  },
}
