<template>
  <chart-efficacy
    v-if="efficacies.length && hasCropDiseases"
    :styles="styles"
    :chartdata="efficacies"
    :hide-labels="hideLabels"
    :focus="focusStructure"
    v-bind="$attrs"
  ></chart-efficacy>
</template>

<script>
import ChartEfficacy from '@/components/ChartBarsHorizontalWithMax'
import CropDiseasesMixin from '@/components/Spraying/CropDiseasesMixin'

export default {
  mixins: [CropDiseasesMixin],
  components: {
    ChartEfficacy,
  },
  props: {
    spraying: {
      type: Object,
      required: true,
    },
    isPlanning: {
      type: Boolean,
      default: false,
    },
    hideLabels: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 260,
    },
  },
  computed: {
    efficacies() {
      const efficaciesWithoutWhiteMold = this.spraying.eficacias.filter(
        (e) => !this.ignoredDiseases?.includes(e.alvo_id)
      )

      const efficacies = [
        efficaciesWithoutWhiteMold.map((e) =>
          this.isPlanning ? e.eficacia_ajustada : e.eficacia_real
        ),
        efficaciesWithoutWhiteMold.map((e) => e.eficacia_potencial),
        efficaciesWithoutWhiteMold.map((e) => e.sem_registro),
      ]

      return efficacies ?? []
    },
    styles() {
      const heightByDiseases = this.cropDiseasesFiltered?.length * 22.5
      return {
        width: `${this.width}px`,
        height: `${heightByDiseases}px`,
        display: 'flex',
      }
    },
    focusStructure() {
      return {
        firstFocus: this.findIndex(this.spraying.doenca_prioritaria_id),
        secondFocus: this.findIndex(this.spraying.doenca_secundaria_1_id),
      }
    },
  },
  methods: {
    findIndex(target) {
      if (this.spraying.doenca_prioritaria_id == 13) {
        return this.cropDiseasesFiltered.findIndex(
          (disease) => disease.id == target
        )
      }
      return null
    },
  },
}
</script>
